import React from "react";
import DefaultLayout from "../../components/DefaultLayout";
import Hero from "../../components/bricks/Hero";
import FeatureOverview from "../../components/FeatureOverview";
import ThemeBox from "../../components/bricks/ThemeBox";
import {themeBright} from "../../page-styles/landingpage.css";
import ThemedSingleFeature from "../../components/bricks/ThemedSingleFeature";
import {Box} from "../../components/xui/Box";
import YoutubeVideo from "../../components/bricks/YoutubeVideo";

const Features = ({location}) => (
  <DefaultLayout
    title="Unique Hand Design"
    description="Learn how Codecks' uses the idea of the hand to signal the state of your and your team's progress."
    location={location}
  >
    <Hero size="sm" title="Unique Hand Design">
      <Box width="100%">
        <YoutubeVideo videoId="Raovt6o5fpI" />
      </Box>
    </Hero>
    <ThemeBox theme={themeBright} withBorder>
      <ThemedSingleFeature title="What's next?" childAsDescription img="hand/whats-next.png">
        In Codecks Hands are personal todo lists. They can contain any of your owned cards,
        independent of project, milestone or priority. You can drag and drop cards to sort them in
        any order that you want. In a card game this would be the selection of cards that you’re
        planning to play next.
      </ThemedSingleFeature>

      <ThemedSingleFeature title="Face-up" childAsDescription img="hand/face-up.png" imgRight>
        Besides your own Hand you can inspect the Hands of your team members. This gives you a quick
        overview of what they are working on right now and what they’re planning to do next.
      </ThemedSingleFeature>

      <ThemedSingleFeature title="All access" childAsDescription img="hand/all-access.png">
        The Hand tab also gives you quick access to all the ongoing conversations (reviews, blocks
        and comments) that you’re involved in and to your bookmarked cards.
      </ThemedSingleFeature>
    </ThemeBox>
    <FeatureOverview />
  </DefaultLayout>
);

export default Features;
